import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";

//const endpoint = 'http://192.168.0.106:8000/api/';
//const endpoint = 'http://localhost:8888/bdlawservice_admin/public/api/';
//siddiqueenterprise.com
const endpoint = "https://admin.bdlawservice.com/public/api/";
//const endpoint = "https://admin.siddiqueenterprise.com/public/api/";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class AppellatedivisionService {
  constructor(private http: HttpClient) {}

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  getCasetype(postData): Observable<any> {
    return this.http
      .post(endpoint + "adcasetype", postData, httpOptions)
      .pipe(map(this.extractData));
  }
   getADMaxd(): Observable<any> {
    return  this.http.get(`https://reactnative.siddiqueenterprise.com/public/api/getADMaxDate`, {responseType: 'text'})
  
  }

  postSaveADCase(postData): Observable<any> {
    return this.http
      .post(endpoint + "saveadnewcase", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  postADcheckduplicate(postData): Observable<any> {
    return this.http 
      .post(endpoint + "savehdnewADcaseAddcheck", postData, httpOptions)
      .pipe(map(this.extractData));
  }
  getTotalcaselistad(postData): Observable<any> {
    return this.http
      .post(endpoint + "totalcaselistad", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getTotalcaselistaddetails(postData): Observable<any> {
    return this.http
      .post(endpoint + "totalcaselistaddetails", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getTotalcaselistadcount(postData): Observable<any> {
    return this.http
      .post(endpoint + "totalcaselistadcount", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getTodayTotalcaselisthdcount(postData): Observable<any> {
    return this.http
      .post(endpoint + "todaytotalcaselistadcount", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getTotalcaselistCountTW(postData): Observable<any> {
    return this.http
      .post(endpoint + "totalcaselisttwadcount", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getTotalcasesummaryLW(postData): Observable<any> {
    return this.http
      .post(endpoint + "adcasesummarydetailslw", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getTotalcasesummaryLWSadmin(postData): Observable<any> {
    return this.http
      .post(endpoint + "adcasesummarydetailslw", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getDraftStatus(postData): Observable<any> {
    if (postData.searchDate.getFullYear() == new Date().getFullYear()) {
      return this.http
        .post(endpoint + "ad_is_draft_copy", postData, httpOptions)
        .pipe(map(this.extractData));
    }
  }

  getCasetoday(postData): Observable<any> {
    return this.http
      .post(endpoint + "ad_casetoday", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getPreviousResult(postData): Observable<any> {
    return this.http
      .post(endpoint + "ad_previous_result", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getPreviousResultDateTime(postData): Observable<any> {
    return this.http
      .post(endpoint + "ad_prev_result_datetime", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getPreviousDate(postData): Observable<any> {
    return this.http
      .post(endpoint + "ad_prev_date", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  /* Appellate Court Wise */

  getCourtlist(postData): Observable<any> {
    return this.http
      .post(endpoint + "ad_courtlist", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getCourtwise(postData): Observable<any> {
    return this.http.post(endpoint + "ad_courtwise", postData, httpOptions);
  }

  getCourtWiseJusticeName(postData): Observable<any> {
    return this.http.post(
      endpoint + "ad_courtwiseJusticeName",
      postData,
      httpOptions
    );
  }

  getDraftStatusAd(postData): Observable<any> {
    if (postData.searchDate2.getFullYear() == new Date().getFullYear()) {
      return this.http
        .post(endpoint + "ad_is_draft_copy", postData, httpOptions)
        .pipe(map(this.extractData));
    }
  }

  /* Appellate Court Wise */

  /* Appellate Page Wise */

  getPagelist(postData): Observable<any> {
    return this.http
      .post(endpoint + "ad_pagelist", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getPagewise(postData): Observable<any> {
    return this.http
      .post(endpoint + "ad_page_wise", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  /* Appellate Page Wise */

  /* SEARCH YOUR CASE API FUNCTION */
  getCasetypeTitle(id): Observable<any> {
    return this.http
      .post(endpoint + "adcasetype/" + id, httpOptions)
      .pipe(map(this.extractData));
  }

  getCourtTitle(id): Observable<any> {
    return this.http
      .post(endpoint + "ad_court_detail/" + id, httpOptions)
      .pipe(map(this.extractData));
  }

  getJusticeTitle(id): Observable<any> {
    return this.http
      .post(endpoint + "ad_justicelist/" + id, httpOptions)
      .pipe(map(this.extractData));
  }

  getCauselistad(postData): Observable<any> {
    return this.http
      .post(endpoint + "ad_causelist", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getCauselisthistoryad(postData): Observable<any> {
    return this.http
      .post(endpoint + "ad_causelisthistory", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getCauseListHistoryByCourtService(postData): Observable<any> {
    return this.http
      .post(endpoint + "ad_causelisthistorybycourt", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getCauseListHistoryByPageService(postData): Observable<any> {
    return this.http
      .post(endpoint + "ad_causelisthistorybypageno", postData, httpOptions)
      .pipe(map(this.extractData));
  }
  /* END ----- SEARCH YOUR CASE API FUNCTION */


  saveOrEditAdRemarks(postData): Observable<any> {
    return this.http
      .post(endpoint + "saveOrEditRemarks", postData, httpOptions)
      .pipe(map(this.extractData));
  }


}
