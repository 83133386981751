import { Component, OnInit } from '@angular/core';
import { CauselistsearchService } from '../../../causelistsearch.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { CasetodayService } from 'src/app/casetoday.service';

@Component({
  selector: 'app-new-case-request-highcourt',
  templateUrl: './new-case-request-highcourt.component.html',
  styleUrls: ['./new-case-request-highcourt.component.css']
})
export class NewCaseRequestHighcourtComponent implements OnInit {
  
  casetypedata:any = [];
  //case_type:number;
  case_type: any;
  case_type_text:string;
  case_no_plus:string;
  //case_no:number;
 // year:number;
  //pr:number;

  case_no:any;
  year:any;
  pr:any;
  remarks:any;

  current_laywerCode:any = [];
  
  SuccessMSG: boolean = false;


  errorMsg: boolean = false;
  errorMsg2: boolean = false;
  errorMsg3: boolean = false;
  errorMsg4: boolean = false;
  errorMsgDup: boolean = false;
  errorMsgonlineDup: boolean = false;
  //errorMsg5: boolean = false;

  caseRequest:any=[];
  caseRequestData:any[];

  errorMessage: string;
  isLoading: boolean = true;

  editKey: number=0;

  current_lawyerType: string;


  constructor( 
    public rest:CauselistsearchService, 
    public casetodayService:CasetodayService, 
    private route: ActivatedRoute, 
    private router: Router,
    public app:AppComponent
  ) { 

    if (!this.app.loggedIn()) {
      this.router.navigate(['../']);
    }  

  }

  ngOnInit() {

    this.current_laywerCode = this.app.getLawyerCode();
    this.current_lawyerType = this.app.getLawyerType();

    this.caseRequestData = JSON.parse(localStorage.getItem("caseRequest"));
    this.getCasetype();
  }

  getCasetype() {
    let postData = {
      //case_type: this.case_type,
    }
    //console.log(postData);

    this.rest
        .getCasetype(postData)
        .subscribe(
          casetypedata => {
                this.casetypedata = casetypedata
                this.isLoading = true
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }
  
  onAdd(){


    if(this.case_type === undefined || this.case_type == "" || this.case_type == null){
      this.errorMsg = true;
      this.errorMsgonlineDup=false;
      this.errorMsgDup=false; 
      return;
    } 
    else if (this.case_no === undefined || this.case_no == "" || this.case_no == null){
      this.errorMsg  = false;
      this.errorMsgonlineDup=false;
      this.errorMsgDup=false; 
      this.errorMsg2 = true;
      return;
    } 
    else if (this.year === undefined || this.year == "" || this.year == null || this.year.length != 4){
      this.errorMsg  = false;
      this.errorMsg2 = false;
      this.errorMsgonlineDup=false;
      this.errorMsgDup=false; 
      this.errorMsg3 = true;
      //this.errorMsg5 = true;
      return;
    }
    else if (this.pr === undefined || this.pr == "" || this.pr == null){
      this.errorMsg  = false;
      this.errorMsg2 = false;
      this.errorMsg3 = false;
      this.errorMsgonlineDup=false;
      this.errorMsgDup=false; 
      this.errorMsg4 = true;
      return;
    }
/*
    else if (this.year.length != 4 ){
      this.errorMsg  = false;
      this.errorMsg2 = false;
      this.errorMsg3 = false;
      this.errorMsg4 = false;
      this.errorMsg5 = true;
      return;
    }*/


    //this.errorMsg5 = false;
    this.errorMsg4 = false;
    this.errorMsg3 = false;
    this.errorMsg2 = false;
    this.errorMsg  = false;
    this.errorMsgonlineDup=false;
    this.errorMsgDup=false; 


    if(this.editKey>0){
      this.caseRequest = JSON.parse(localStorage.getItem("caseRequest"));
      this.case_type_text=this.caseRequest[this.editKey-1]['case_type_text'];
      this.caseRequest.splice(this.editKey-1,1);
      this.editKey=0;
    }
  
    var array = new Object();    
    array["lawyer_code"] = this.current_laywerCode;
    array["case_type"] = this.case_type;
    array["case_type_text"] = this.case_type_text;
    array["case_no_plus"] = this.case_no_plus;
    array["case_no"] = this.case_no;
    array["year"] = this.year;
    array["pr"]=this.pr;
    array["remarks"]=this.remarks;

    var postData = { itemcart:  JSON.stringify(array)};
    var filtered = this.caseRequest.filter(item => item.lawyer_code === this.current_laywerCode && item.case_type === this.case_type && item.case_no === this.case_no && item.year === this.year  );
    if(filtered.length == 0){
    this.casetodayService
      .postHDcheckduplicate(postData)
      .subscribe(
        caseduplicatedata => {
         //console.log(caseduplicatedata.code);
         
         if(caseduplicatedata.code===100){
          this.errorMsgonlineDup=true;
         
          this.case_type= 0;
          this.case_type_text = "";
          this.case_no_plus = "";
          this.case_no = null;
          this.year = null;
          this.pr= null;
          this.remarks= null;
          return;    
  
      }else{
       this.caseRequest.push(array);
    
    localStorage.setItem("caseRequest", JSON.stringify(this.caseRequest));

    this.caseRequestData = JSON.parse(localStorage.getItem("caseRequest"));

    this.case_type= 0;
    this.case_type_text = "";
    this.case_no_plus = "";
    this.case_no = null;
    this.year = null;
    this.pr= null;
    this.remarks= null;
  
      }
     
     
  
      
    },
    error => {
        this.errorMessage = <any>error
        this.isLoading = false
    }
  );
  } else{
    this.errorMsgDup=true; 
    this.case_type= 0;
    this.case_type_text = "";
    this.case_no_plus = "";
    this.case_no = null;
    this.year = null;
    this.pr= null;
    this.remarks= null;
    return;
  }

   

  }

  selectChangeHandler (event: Event) {

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    this.case_type_text = selectedOptions[selectedIndex].text;
    this.case_no=null;
    this.year=null;
    
  }

  onDelete(val){
    this.caseRequestData = JSON.parse(localStorage.getItem("caseRequest"));
    this.caseRequestData.splice(val,1)
    this.caseRequest.splice(val,1)
    localStorage.setItem("caseRequest", JSON.stringify(this.caseRequestData));
  }

  onEdit(val){
    //console.log(this.caseRequest[val]);
    this.editKey=val+1;
    this.caseRequestData = JSON.parse(localStorage.getItem("caseRequest"));
    this.case_type=this.caseRequestData[val]['case_type'];
    this.case_no_plus=this.caseRequestData[val]['case_no_plus'];
    this.case_no=this.caseRequestData[val]['case_no'];
    this.year=this.caseRequestData[val]['year'];
    this.pr=this.caseRequestData[val]['pr'];
    this.remarks=this.caseRequestData[val]['remarks'];
  }

  saveHDNewCase() {
    let postData = {
      //case_type: this.case_type,
      allcase: JSON.parse(localStorage.getItem("caseRequest")),
    }

    this.SuccessMSG = false


    this.casetodayService
    .postSaveHDCase(postData)
    .subscribe(
      casetypedata => {
            this.isLoading = true
            this.caseRequestData = [];
            this.caseRequest=[];
            localStorage.removeItem("caseRequest");
            this.SuccessMSG = true;
            this.router.navigate(['NewCaseEntryInfo']);
        },
        error => {
            this.errorMessage = <any>error
            this.isLoading = false
        }
    );
  }

}